<template>
  <ul class="menu-nav">
    <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">{{ $t('MENU.DASHBOARD') }}</span>
        </a>
      </li>
    </router-link>

    <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu"  
        v-bind:class="{ 'menu-item-open': hasActiveChildren('users') }">
      <a href="javascript:;" class="menu-link menu-toggle">
        <span class="svg-icon menu-icon">
          <i class="menu-icon flaticon2-user-1"></i>
        </span>
        <span class="menu-text">{{ $t('MENU.users_roles_management') }}</span>
        <i class="menu-arrow"></i>
      </a>

      <div class="menu-submenu" kt-hidden-height="80">
        <i class="menu-arrow"></i>
        <ul class="menu-subnav">

          <router-link  v-if="$can('users.list')" to="/users/users" v-slot="{ href, navigate, isActive, isExactActive }">
          <li class="menu-item" aria-haspopup="true" data-menu-toggle="hover" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
                <a :href="href" class="menu-link" @click="navigate">
                  <i class="menu-icon flaticon2-user-1"></i>
                  <span class="menu-text">{{ $t('MENU.USERS') }}</span>
                </a>
            </li>
          </router-link>
          <router-link  v-if="$can('roles.list')" to="/users/roles" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-settings-1"></i>
                <span class="menu-text">{{ $t('MENU.Roles_Management') }}</span>
              </a>
            </li>
          </router-link>

          <router-link  v-if="$can('admin_accounts.update')" to="/admin_account/setting" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon2-user-1"></i>
                <span class="menu-text">{{ $t('MENU.admin_accounts') }}</span>
              </a>
            </li>
          </router-link>

        </ul>
      </div>

    </li>
    <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/settings') }">
      <!-- <li class="menu-item menu-item-submenu" :class="[isActive && 'menu-item-open', isExactActive && 'menu-item-open']"  aria-haspopup="true" data-menu-toggle="hover"> -->
      <a href="javascript:;" class="menu-link menu-toggle">
            <span class="svg-icon menu-icon">
            <!-- icon -->
            <i class="menu-icon flaticon2-settings"></i>
              <!-- End icon -->
            </span>
        <span class="menu-text">{{ $t('MENU.setting_management') }}</span>
        <i class="menu-arrow"></i>
      </a>

      <div class="menu-submenu" kt-hidden-height="80">
        <i class="menu-arrow"></i>
        <ul class="menu-subnav">



          <router-link v-if="$can('countries.list')" to="/settings/countries" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-earth-globe"></i>
                <span class="menu-text">{{ $t('MENU.Countries') }}</span>
              </a>
            </li>
          </router-link>


          <router-link  v-if="$can('categories.list')" to="/settings/categories" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon la la-sitemap"></i>
                <span class="menu-text">{{ $t('MENU.Categories') }}</span>
              </a>
            </li>
          </router-link>
          <router-link  v-if="$can('module_settings.list')"  to="/settings/module-settings" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon la la-sitemap"></i>
                <span class="menu-text">{{ $t('MENU.module_settings') }}</span>
              </a>
            </li>
          </router-link>

          <router-link v-if="$can('business_field.list')"  to="/settings/business-field" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon la la-sitemap"></i>
                <span class="menu-text">{{ $t('MENU.business_field') }}</span>
              </a>
            </li>
          </router-link>

          <router-link v-if="$can('register_company.list')"  to="/settings/register-company" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon la la-sitemap"></i>
                <span class="menu-text">{{ $t('MENU.register_company') }}</span>
              </a>
            </li>
          </router-link>

          <router-link v-if="$can('sync_endpoint.list')"  to="/settings/sync-endpoint" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon la la-sitemap"></i>
                <span class="menu-text">{{ $t('MENU.sync_endpoint') }}</span>
              </a>
            </li>
          </router-link>

<!--          -->
          <router-link v-if="$can('periods.update')"  to="/settings/periods" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon la la-sitemap"></i>
                <span class="menu-text">{{ $t('MENU.periods') }}</span>
              </a>
            </li>
          </router-link>





          <!-- End Router -->
        </ul>
      </div>
    </li>
    <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/agents') }">
      <a href="javascript:;" class="menu-link menu-toggle">
            <span class="svg-icon menu-icon">
            <!-- icon -->
            <i class="menu-icon flaticon2-settings"></i>
              <!-- End icon -->
            </span>
        <span class="menu-text">{{ $t('MENU.agents_management') }}</span>
        <i class="menu-arrow"></i>
      </a>

      <div class="menu-submenu" kt-hidden-height="80">
        <i class="menu-arrow"></i>
        <ul class="menu-subnav">


          <router-link  v-if="$can('agents.list')" to="/agents/agents" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-earth-globe"></i>
                <span class="menu-text">{{ $t('MENU.agents') }}</span>
              </a>
            </li>
          </router-link>
<!--          v-if="$can('commission.list')"-->
          <router-link  to="/agents/commission" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-earth-globe"></i>
                <span class="menu-text">{{ $t('MENU.commission_management') }}</span>
              </a>
            </li>
          </router-link>
<!--          v-if="$can('agents_customers.list')"-->
          <router-link  to="/agents/agents-customers" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-earth-globe"></i>
                <span class="menu-text">{{ $t('MENU.agents_and_customers') }}</span>
              </a>
            </li>
          </router-link>
<!--          v-if="$can('apply_commission.list')"-->
          <router-link  to="/agents/apply-commission" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-earth-globe"></i>
                <span class="menu-text">{{ $t('MENU.apply_commission') }}</span>
              </a>
            </li>
          </router-link>
<!--          v-if="$can('partners.list')"-->
          <router-link  to="/agents/partners" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-earth-globe"></i>
                <span class="menu-text">{{ $t('MENU.partners') }}</span>
              </a>
            </li>
          </router-link>
          <!-- End Router -->
        </ul>
      </div>
    </li>

    <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/subscription') }">
      <a href="javascript:;" class="menu-link menu-toggle">
            <span class="svg-icon menu-icon">
            <!-- icon -->
            <i class="menu-icon flaticon2-settings"></i>
              <!-- End icon -->
            </span>
        <span class="menu-text">{{ $t('MENU.subscription') }}</span>
        <i class="menu-arrow"></i>
      </a>

      <div class="menu-submenu" kt-hidden-height="80">
        <i class="menu-arrow"></i>
        <ul class="menu-subnav">



          <router-link v-if="$can('plans.list')" to="/subscription/plans" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-earth-globe"></i>
                <span class="menu-text">{{ $t('MENU.plans') }}</span>
              </a>
            </li>
          </router-link>

          <router-link v-if="$can('requests_tenant.list')" to="/subscription/request-tenant-subdomain" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-earth-globe"></i>
                <span class="menu-text">{{ $t('MENU.request_tenant_subdomain') }}</span>
              </a>
            </li>
          </router-link>

          <router-link  v-if="$can('management_subdomains.list')" to="/subscription/management-subdomain" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-earth-globe"></i>
                <span class="menu-text">{{ $t('MENU.management_subdomain') }}</span>
              </a>
            </li>
          </router-link>

          <router-link v-if="$can('db_managements.list')" to="/subscription/management-database" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-earth-globe"></i>
                <span class="menu-text">{{ $t('MENU.management_database') }}</span>
              </a>
            </li>
          </router-link>

          <router-link v-if="$can('required_tenants.list')" to="/subscription/required-tenant" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-earth-globe"></i>
                <span class="menu-text">{{ $t('MENU.required_tenants') }}</span>
              </a>
            </li>
          </router-link>

<!--          v-if="$can('payments_moyasar.list')"-->
          <router-link  to="/subscription/payments-moyasar" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-earth-globe"></i>
                <span class="menu-text">{{ $t('MENU.payments_moyasar') }}</span>
              </a>
            </li>
          </router-link>
<!--          v-if="$can('payments_paypal.list')"-->
          <router-link to="/subscription/payments-paypal" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-earth-globe"></i>
                <span class="menu-text">{{ $t('MENU.payments_paypal') }}</span>
              </a>
            </li>
          </router-link>


          <router-link to="/subscription/payments-cash" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-earth-globe"></i>
                <span class="menu-text">{{ $t('MENU.payments_cash') }}</span>
              </a>
            </li>
          </router-link>

          <router-link to="/subscription/bank-transfers" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-earth-globe"></i>
                <span class="menu-text">{{ $t('MENU.bank_transfers') }}</span>
              </a>
            </li>
          </router-link>

          <router-link to="/subscription/invoices" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-earth-globe"></i>
                <span class="menu-text">{{ $t('MENU.invoices') }}</span>
              </a>
            </li>
          </router-link>

          <router-link to="/subscription/reset-company" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-earth-globe"></i>
                <span class="menu-text">{{ $t('MENU.reset_companies') }}</span>
              </a>
            </li>
          </router-link>

  
          <router-link v-if="$can('offers.list')" to="/subscription/offers" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-earth-globe"></i>
                <span class="menu-text">{{ $t('MENU.offers') }}</span>
              </a>
            </li>
          </router-link>

<!--          v-if="$can('extension-requests.list')"-->
          <router-link to="/subscription/extension-requests" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-earth-globe"></i>
                <span class="menu-text">{{ $t('MENU.extension_requests') }}</span>
              </a>
            </li>
          </router-link>

<!--          v-if="$can('extension-requests.list')"-->
          <router-link to="/subscription/request-services" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-earth-globe"></i>
                <span class="menu-text">{{ $t('MENU.request_services') }}</span>
              </a>
            </li>
          </router-link>
<!--          v-if="$can('plan_discounts.list')"-->
          <router-link  to="/subscription/plans-discounts" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-earth-globe"></i>
                <span class="menu-text">{{ $t('MENU.discounts') }}</span>
              </a>
            </li>
          </router-link>

          <!-- End Router -->
        </ul>
      </div>
    </li>


    <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/reports') }">
      <a href="javascript:;" class="menu-link menu-toggle">
            <span class="svg-icon menu-icon">
            <!-- icon -->
            <i class="menu-icon flaticon2-settings"></i>
              <!-- End icon -->
            </span>
        <span class="menu-text">{{ $t('MENU.reports') }}</span>
        <i class="menu-arrow"></i>
      </a>

      <div class="menu-submenu" kt-hidden-height="80">
        <i class="menu-arrow"></i>
        <ul class="menu-subnav">


<!--          v-if="$can('report_subscription.list')"-->
          <router-link  to="/reports/reports-subscription" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-earth-globe"></i>
                <span class="menu-text">{{ $t('MENU.reports_subscriptions') }}</span>
              </a>
            </li>
          </router-link>

          <router-link  to="/reports/reports-expired-subscription" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-earth-globe"></i>
                <span class="menu-text">{{ $t('MENU.reports_expired_subscriptions') }}</span>
              </a>
            </li>
          </router-link>

          <router-link  to="/reports/reports-agents-dues" v-slot="{ href, navigate, isActive, isExactActive }">
            <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon flaticon-earth-globe"></i>
                <span class="menu-text">{{ $t('MENU.reports_agents_dues') }}</span>
              </a>
            </li>
          </router-link>


          <!-- End Router -->
        </ul>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: "KTMenu",
    // computed: {
    //     user_personal_info() {
    //         return this.$store.state.profile.user_personal_info;
    //     },
    //     getFullName() {
    //         return (
    //             this.user_personal_info.name + " " + this.user_personal_info.surname
    //         );
    //     }
    // },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  },
};
</script>
